<template>
    <div class="page-brand-detail g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>品牌中心</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="brand-det">
                <div class="pic">
                    <img :src="PPlogoimg" alt="">
                </div>
                <div class="text">
                    <div v-html="`${PPcontentText}`"></div>
                </div>
            </div>
            <div class="g-title">
                <img src="@/assets/img/g-tit4.png" alt="">
            </div>
            <div class="g-table-wrap">
                <table class="g-table">
                    <thead>
                    <tr>
                        <td>产品编号</td>
                        <td>产品描述</td>
                        <td>品牌</td>
                        <td>CAS号</td>
                        <td>包装规格</td>
                        <td>售价(￥)</td>
                        <td>会员价(￥)</td>
                        <td>库存</td>
                        <td>购买</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in hotgoodList" :key="index" @click="jumpToDet(item)">
                        <td>
                            <p>{{ item.goodsNumber }}</p>
                        </td>
                        <td>
							<p>{{item.name}}  {{item.nameEn}}</p>
                            <p v-if="item.dangerNature"><span class="gray">监管条件：</span> <span class="red">{{ item.dangerNature }}</span></p>
                        </td>
                        <td>
                            {{ item.brandName}}
                        </td>
                        <td>
                            <div class="c-1c8f8e cursorPointer" @click="goSearchResult(item.casNumber)">{{ item.casNumber }}</div>
                        </td>
                        <td>
                            {{ item.specification }}
                        </td>
                        <td style="min-width: 100px;">
							<div class="xiahuaX" v-if="item.normalPrice==0"  @click.stop="inquiry(item)">询价</div>
							<div class="" v-else>
								<div class="displayAli_cen" v-if="islogin">
									{{item.normalPrice}}
								</div>
								<div v-else>
									<div :class="item.normalPrice > item.discountPrice?'shanchuX':''">{{item.normalPrice}}</div>
									<div class="displayAli_cen c-ff0000 positionRel" v-if="item.normalPrice > item.discountPrice">
										{{item.discountPrice}}
										<img src="@/assets/image/cu.png" style="width: 25px;height: 25px;position: absolute;right: 5px;">
									</div>
								</div>
							</div>
                            <!--                                    <div class="g-btn small"  @click.stop="inquiry(item)">询价</div>-->
                        </td>
                        <td style="min-width: 100px;max-width: 120px;position: relative">
							<div class="xiahuaX" v-if="item.normalPrice==0"  @click.stop="inquiry(item)">询价</div>
							<div class="ftwt displayAli_cen" v-else>
								{{islogin?item.customerPrice < item.discountPrice ? item.customerPrice: item.customerPrice > item.discountPrice ? item.discountPrice : item.normalPrice:'登录查看'}}
								<img v-if="islogin&&((item.customerPrice < item.discountPrice) || (item.customerPrice > item.discountPrice))" src="@/assets/image/cu.png" style="width: 25px;height: 25px;position: absolute;right: 10px;">
							</div>
						</td>
                        <td>
<!--                            <p v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }}-->
<!--                                {{ item1.goodsCount > 50?'>50':item1.goodsCount }}</p>-->
                            <p v-if="item.goodsStockList&&item.goodsStockList.length>0">
                                <span class="white_space_nowarp text_hidden1" v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }} {{ item1.goodsCount > 50?'>50':'<50' }}</span>
                            </p>
                            <p v-else>订货</p>
                        </td>
                        <td>
                            <div class="tool" @click.stop>
                                <el-input-number v-model="item.num" :min="1"></el-input-number>
                                <div @click="joinCart(item,item.num)" class="icon">
                                    <img src="@/assets/img/cart2.png" alt="">
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="mat20 text_cen" v-if="hotgoodList.length==0">暂无数据</div>

            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="pageSizes"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="goodsTotal">
                </el-pagination>
            </div>
        </div>

        <div class="m-pop" v-if="showPop">
            <div class="pop-con" style="max-width: 1200px;">
                <div class="title" style="border-bottom: none;height: 40px;">
                    <h3></h3>
                    <div class="close" @click="showPop = false"></div>
                </div>
                <div class="inner-con" style="padding-top: 0;">
                    <div class="g-table-wrap">
                        <table class="g-table">
                            <thead>
                            <tr>
                                <td>产品编号</td>
                                <td>产品描述</td>
                                <td>品牌</td>
                                <td>CAS号</td>
                                <td>包装规格</td>
                                <td>售价(￥)</td>
                                <td>会员价(￥)</td>
                                <td style="text-align: center;">库存</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in inquiryData" :key="index">
                                <td>
                                    <p>{{ item.goodsNumber }}</p>
                                </td>
                                <td>
									<p>{{item.name}}  {{item.nameEn}}</p>
                                    <p v-if="item.dangerNature"><span class="gray">监管条件：</span> <span class="red">{{ item.dangerNature }}</span></p>
                                </td>
                                <td>
                                    {{ item.brandName}}
                                </td>
                                <td>
                                    <div class="ftwt">{{ item.casNumber }}</div>
                                </td>
                                <td>
                                    {{ item.specification }}
                                </td>
								<td>询价</td>
								<td>询价</td>
                                <td>
                                    <p v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }}
                                        {{ item1.goodsCount > 50?'>50':item1.goodsCount }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-row">
                        <el-form ref="inquiryForm" :model="inquiryForm" :rules="inquiryRules" label-width="80px">
                            <el-form-item label="联系人" prop="username">
                                <el-input v-model="inquiryForm.username"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="inquiryForm.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="单位名称" prop="companyName">
                                <el-input v-model="inquiryForm.companyName"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="g-btn form-btn" @click="submitInquiry">提交</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {brandinfoDetail, goodsInfoPage} from "@/api/home";
import Bus from "@/utils/bus";
import {consultgoodsSave} from "@/api/Product";
import {joincartSave} from "@/api/transaction";
import {userDetail} from "@/api/account";

export default {
    name: "brandDetail",
    data() {
        return {
            table: {
                n0:1,
                n1:1,
                n2:1,
                n3:1,
                n4:1,
            },
            PPlogoimg:'',
            PPcontentText:'',
            hotgoodList:[], // 热卖产品
            current:1, // 页数
            size:10, // 每页条数
            pageSizes:[10, 20, 50],
            goodsTotal:0, // 数据总数
            islogin:localStorage.getItem('Token')?true:false, // 是否登录

            inquiryData:[], //询价数据
            inquiryForm: {
                username: '',
                phone: '',
                companyName	: '',
                goodsId:'',
            },
            inquiryRules: {
                username: [{required: true, trigger: 'blur', message: '必填项'}],
                phone: [{required: true, trigger: 'blur', message: '必填项'}],
                companyName: [{required: true, trigger: 'blur', message: '必填项'}],
            },
            showPop: false,
	        brandId:"", // 品牌id
        }
    },
    mounted() {
        console.log(this.$route.query) // 获取传递参数
	    this.brandId=this.$route.query.id
        this.getbrandinfoDetail(this.$route.query.id)
        this.getgoodsInfoPage(this.$route.query.id)
        this.getuserDetail()
    },
    methods:{
        // 筛选cas
        goSearchResult(casNumber){
            this.$router.push({path:'/SearchResult',query: {casNumber:casNumber}}) //
        },
        // 去商品详情
        jumpToDet:function (item) {
            this.$router.push({path:'/ProductDetail',query: {id:item.id}}) //
        },
        //询价
        inquiry(item){
            this.showPop = true
            this.inquiryData=[]
            this.inquiryData.push(item)
            this.inquiryForm.goodsId=item.id
        },
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getgoodsInfoPage();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getgoodsInfoPage();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getgoodsInfoPage();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getgoodsInfoPage();
        },

        //  -------------------------接口请求
        getgoodsInfoPage(id){
            let param={
                size:this.size,
                current:this.current,
				brandIds:this.brandId.split(','), // 品牌id
                isBrandHot:1, // 品牌推荐
				// type:0, // 类型：0品牌商品热门，1产品资讯热门
            }
            goodsInfoPage(param).then((res) => {
                if (res.data.code==200) {
                    if(res.data.data.records.length>0){
                        this.associationGoodList=[]
                        res.data.data.records.forEach((item,index)=>{
                            item.customerPrice=parseFloat(item.customerPrice)
                            item.discountPrice=parseFloat(item.discountPrice)
                            item.normalPrice=parseFloat(item.normalPrice)
                            item.num=1
                        })
                        this.hotgoodList=res.data.data.records
                        console.log(this.goodsList)
                        this.$forceUpdate()
                    }else{
                        if(this.current!=1){
                            this.current-- // 没查到 页数减少
                        }
                    }
                    this.goodsTotal=Number(res.data.data.total)
                }

            })
        },
        // 获取用户信息
        async getuserDetail(){
            if(localStorage.getItem('userInfo')){
                console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
                let userId=JSON.parse(localStorage.getItem('userInfo')).userId
                userDetail({userId:userId}).then((res) => {
                    if (res.data.code==200) {
                        let data=res.data.data
                        if(data.companyName && data.email){ // 工作单位跟邮箱
                            this.isuserinfo=true  // 用户信息完善
                        }
                    }

                })
            }

        },
        // 点击加入购物车
        joinCart(item,num){
            console.log(item,num)
			if(!localStorage.getItem('Token') || this.$utils.isTokenExpired()){
				Bus.$emit('re-login', '未登录')
				return
			}
            if(!this.isuserinfo){
                this.$message({
                    type: 'warning',
                    message: "请先完善个人信息！",
                    duration: 3000
                });
                this.$router.push({path:'/vip',query:{type:"完善信息"}}) // 去完善个人信息
                return
            }
            if(item.normalPrice==0){
                this.$message({
                    type: 'warning',
                    message: "询价商品不能加入购物车！",
                    duration: 3000
                });
                return
            }
            let param={
                goodsId:item.id,
                customerId:localStorage.getItem('customId'),
                count:num
            }
            joincartSave(param).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "已加入购物车",
                        duration: 2000
                    });
                    Bus.$emit('addCart', '加购物车')
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },
        // 提交询价
        submitInquiry(){
            this.$refs.inquiryForm.validate(valid => {
                if (valid) {
                    if(!(/^1[3456789]\d{9}$/.test(this.inquiryForm.phone))){
                        this.$message({
                            type: 'warning',
                            message: '请填写正确的手机号！',
                            duration: 1500
                        });
                        return
                    }
                    var params = {
                        // refreshToken: localStorage.getItem('refresh_Token'),
                        username: this.inquiryForm.username,
                        phone: this.inquiryForm.phone,
                        companyName: this.inquiryForm.companyName,
                        goodsId: this.inquiryForm.goodsId,
                    };
					console.log('提交询价')
					consultgoodsSave(params).then((res) => {
						if(res.data.code==200){
							this.showPop = false
							this.$message({
								type: 'success',
								message: "提交成功",
								duration: 2000
							});
						}else{
							this.$message({
								type: 'error',
								message:res.data.message,
								duration: 2000
							});
						}
					})
                }
            })

        },

        // 品牌详情
        getbrandinfoDetail(id){
            let param={
                id:id,
            }
            brandinfoDetail({id:id}).then((res) => {
                if (res.data.code==200) {
                    this.PPlogoimg=res.data.data.logoUrl
                    this.PPcontentText=this.$utils.showHtml(res.data.data.introText)
                }

            })
        },
    },

}
</script>

<style scoped>

</style>
